import { Form } from 'antd'
import { MaskedInput } from 'antd-masked-input'
import React, { useMemo } from 'react'
import { profileScreen } from '../../../../translates'
import { validatePassportIssuerCode } from '../../../../helper'
import SberIcon from '../../SberIcon'

const PassportIssuerCodeFormItem = ({
  label = profileScreen.input.passportIssuerCode.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.passportIssuerCode.message,
  size = 'large',
  placeholder = profileScreen.input.passportIssuerCode.placeholder,
  showCheckIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showCheckIcon
      ? <>{label}<SberIcon style={{ marginLeft: '0.5rem' }} /></>
      : label
  }, [label, showCheckIcon])

  return (
    <Form.Item
      name={['personalData', 'passport', profileScreen.input.passportIssuerCode.name]}
      label={labelParam}
      rules={rules ?? [{ required: requiredRule, message: messageRule, validator: validatePassportIssuerCode }]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='111-111'
      />
    </Form.Item>
  )
}
export default PassportIssuerCodeFormItem
