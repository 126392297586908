import React from 'react'
import envJson from './env.json'
import { profileScreen } from './translates'
import { AppConfig } from './AppConfig'

export const ALL = '*'
export const NEW = 'new'
export const ACCEPTED = 'accepted'
export const ACCEPT = 'accept'
export const ACCEPT_CONDITION = 'acceptCondition'
export const DECLINED = 'declined'
export const NOT_FILLED = 'notFilled'
export const FILLED_IN_SHOPPER = 'filledInShopper'
export const FILLED_IN_TINKOFF = 'filledInTinkoffId'
export const DELETED = 'deleted'
export const DECLINE = 'decline'
export const NONE = 'none'
export const UNKNOWN_DOC = 'Неизвестный тип документа'
export const STATE_CHECKED = 'Проверен'

export const coordinatorTickStatusTimerMs = 10000
export const COORDINATOR_PARTNER_ID = 'coordinatorPartnerId'

export const maxSizeUploadFileMB = {
  passportPhoto1: 10,
  passportPhoto2: 10,
  passportPhoto3: 10,
  default: 10
}
export const twoMinutes = 2 * 60 * 1000
export const oneMinute = 1 * 60 * 1000
export const getShareLinkPath = shareId => window.location.origin + '/api/public/offer/' + shareId
export const getShopperPath = (phone, host = envJson.partnerActivationHost) => host + envJson.partnerActivationSlug + '?phone=' + phone
// export const personalPolicyDocLink = `${envJson.apiEndpoint}public/file/pdpolicy.pdf`
export const personalPolicyDocLink = '/img/pdpolicy.pdf'

export const emptyFilter = { text: '<Нет значения>', value: null }

export const laquo = <>&laquo;</>
export const raquo = <>&raquo;</>
export const nbsp = <>&nbsp;</>
export const mdash = <>&mdash;</>

export const policyTypes = {
  personalDataPolicy: 'personalDataPolicy',
  electronicSignaturePolicy: 'electronicSignaturePolicy',
  partnersSberMarketPolicy: 'partnersSberMarketPolicy',
  cyberSecurityPolicy: 'cyberSecurityPolicy',
  assemblyAndDeliveryPolicy: 'assemblyAndDeliveryPolicy',
  lmkAgreement: 'lmkAgreement'
}
export const breadcrumbSeparator = <span className='ant-breadcrumb-separator'>&gt;</span>
export const formLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
}

export const paginationPageSizeList = [10, 20, 50]

export const partnerLinkedFields = {
  [`personalData.${profileScreen.input.surname.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null,
    [profileScreen.input.bankDetailsPhoto.name]: null,
    [profileScreen.input.innPhoto.name]: null
  },
  [`personalData.${profileScreen.input.firstName.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null,
    [profileScreen.input.bankDetailsPhoto.name]: null,
    [profileScreen.input.innPhoto.name]: null
  },
  [`personalData.${profileScreen.input.middleName.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null,
    [profileScreen.input.bankDetailsPhoto.name]: null,
    [profileScreen.input.innPhoto.name]: null
  },
  [`personalData.passport.${profileScreen.input.passportSeries.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null
  },
  [`personalData.passport.${profileScreen.input.passportNumber.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null
  },
  [`personalData.passport.${profileScreen.input.passportIssuerName.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null
  },
  [`personalData.passport.${profileScreen.input.passportIssuerCode.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null
  },
  [`personalData.passport.${profileScreen.input.passportIssueDate.name}`]: {
    [profileScreen.input.passportPhoto1.name]: null,
    [profileScreen.input.passportPhoto2.name]: null,
    [profileScreen.input.passportPhotoExtraReg.name]: null
  },
  [`personalData.${profileScreen.input.inn.name}`]: {
    [profileScreen.input.innPhoto.name]: null
  },
  [`bankDetails.${profileScreen.input.bankAccount.name}`]: {
    [profileScreen.input.bankDetailsPhoto.name]: null
  },
  [`bankDetails.${profileScreen.input.bankBik.name}`]: {
    [profileScreen.input.bankDetailsPhoto.name]: null
  },
  [`bankDetails.${profileScreen.input.bankName.name}`]: {
    [profileScreen.input.bankDetailsPhoto.name]: null
  },
  [profileScreen.input.passportPhoto1.name]: {
    personalData: {
      passport: {
        [profileScreen.input.passportSeries.name]: null,
        [profileScreen.input.passportNumber.name]: null,
        [profileScreen.input.passportIssuerName.name]: null,
        [profileScreen.input.passportIssuerCode.name]: null,
        [profileScreen.input.passportIssueDate.name]: null
      }
    }
  },
  [profileScreen.input.passportPhoto2.name]: {
    personalData: {
      passport: {
        [profileScreen.input.passportSeries.name]: null,
        [profileScreen.input.passportNumber.name]: null,
        [profileScreen.input.passportIssuerName.name]: null,
        [profileScreen.input.passportIssuerCode.name]: null,
        [profileScreen.input.passportIssueDate.name]: null
      }
    }
  },
  [profileScreen.input.bankDetailsPhoto.name]: {
    bankDetails: {
      [profileScreen.input.bankAccount.name]: null
    }
  }
}

export const partnerListColKeys = {
  registrationDate: 'registrationDate',
  statuses: 'statuses',
  // formStatuses: 'formStatuses',
  profileFormStatuses: 'profileFormStatuses',
  cardStatuses: 'cardStatuses',
  bankRequisiteFormStatuses: 'bankRequisiteFormStatuses',
  smzStatuses: 'smzStatuses',
  youdoStatuses: 'youdoStatuses',
  roles: 'roles',
  name: 'name',
  mobilePhone: 'mobilePhone',
  cityIds: 'cityIds',
  storeName: 'storeName',
  offerExpires: 'offerExpires',
  verificationStatuses: 'verificationStatuses',
  payoutSuspended: 'payoutSuspended',
  lmkFormStatuses: 'lmkFormStatuses',
  employmentTypeFormStatuses: 'employmentTypeFormStatuses',
  legalForms: 'legalForms',
  fireBriefingFormStatuses: 'fireBriefingFormStatuses',
  inn: 'inn',
  statusTimestamp: 'statusTimestamp'
}

export const rolePartnerListColsKeys = {
  defaultGroup: Object.keys(partnerListColKeys).filter(
    k => ![
      partnerListColKeys.inn,
      partnerListColKeys.lmkFormStatuses,
      partnerListColKeys.fireBriefingFormStatuses,
      partnerListColKeys.statusTimestamp
    ].includes(k)
  ),
  [AppConfig.groups.supervisors]: Object.keys(partnerListColKeys).filter(
    k => ![partnerListColKeys.inn, partnerListColKeys.statusTimestamp].includes(k)
  ),
  [AppConfig.groups.citymanagers]: Object.keys(partnerListColKeys).filter(
    k => ![partnerListColKeys.inn, partnerListColKeys.statusTimestamp, partnerListColKeys.lmkFormStatuses].includes(k)
  ),
  [AppConfig.groups.personalmanagers]: Object.keys(partnerListColKeys).filter(
    k => ![
      partnerListColKeys.inn,
      partnerListColKeys.lmkFormStatuses,
      // partnerListColKeys.formStatuses,
      partnerListColKeys.profileFormStatuses,
      partnerListColKeys.cardStatuses,
      partnerListColKeys.payoutSuspended,
      partnerListColKeys.statusTimestamp
    ].includes(k)
  ),
  [AppConfig.groups.contentcoordinators]: Object.keys(partnerListColKeys).filter(
    k => ![
      partnerListColKeys.inn,
      partnerListColKeys.lmkFormStatuses,
      partnerListColKeys.cardStatuses,
      partnerListColKeys.roles,
      partnerListColKeys.cityIds,
      partnerListColKeys.storeName,
      partnerListColKeys.offerExpires,
      partnerListColKeys.verificationStatuses,
      partnerListColKeys.profileFormStatuses
      // partnerListColKeys.formStatuses
    ].includes(k)
  ),
  [AppConfig.groups.recruiter]: Object.keys(partnerListColKeys).filter(
    k => ![
      partnerListColKeys.inn,
      partnerListColKeys.lmkFormStatuses,
      partnerListColKeys.cardStatuses,
      partnerListColKeys.roles,
      partnerListColKeys.cityIds,
      partnerListColKeys.storeName,
      partnerListColKeys.offerExpires,
      partnerListColKeys.verificationStatuses,
      partnerListColKeys.profileFormStatuses
      // partnerListColKeys.formStatuses
    ].includes(k)
  ),
  [AppConfig.groups.researchercoordinators]: Object.keys(partnerListColKeys).filter(
    k => ![
      partnerListColKeys.inn,
      partnerListColKeys.lmkFormStatuses,
      partnerListColKeys.cardStatuses,
      partnerListColKeys.roles,
      partnerListColKeys.cityIds,
      partnerListColKeys.storeName,
      partnerListColKeys.offerExpires,
      partnerListColKeys.verificationStatuses,
      partnerListColKeys.profileFormStatuses
      // partnerListColKeys.formStatuses
    ].includes(k)
  )
}

export const initialBannedPartnerColKeys = [
  partnerListColKeys.registrationDate,
  partnerListColKeys.statuses,
  partnerListColKeys.youdoStatuses,
  partnerListColKeys.roles,
  partnerListColKeys.name,
  partnerListColKeys.mobilePhone,
  partnerListColKeys.storeName,
  partnerListColKeys.statusTimestamp
]

export const partnerRoles4MultiOffer = [
  AppConfig.partnerRoles.packer,
  AppConfig.partnerRoles.courier,
  AppConfig.partnerRoles.footCourier,
  AppConfig.partnerRoles.automotiveCourier,
  AppConfig.partnerRoles.packerAndFootCourier,
  AppConfig.partnerRoles.packerAndAutomotiveCourier,
  AppConfig.partnerRoles.bikeCourier
]
