import { twoMinutes } from '../Constants'
import { useEffect, useState } from 'react'
import { message } from 'antd'
import { errors } from '../translates'

export default function useCommonDict ({
  errorMessage = errors.common,
  queryOptions = {
    staleTime: twoMinutes
  },
  queryMethod,
  queryBody = {},
  successCallback
}) {
  const [entity, setEntity] = useState([])

  const {
    data,
    isError,
    isSuccess,
    isLoading
  } = queryMethod(queryBody, queryOptions)

  useEffect(() => {
    if (isSuccess) {
      const result = successCallback ? successCallback(data) : [...(data?.result || [])]
      setEntity(result)
    }
  }, [isSuccess, data, successCallback])

  useEffect(() => {
    if (isError) {
      message.error(errorMessage)
    }
  }, [errorMessage, isError])

  return {
    data: entity,
    isLoading
  }
}
