import { useCallback } from 'react'

import { errors } from '../translates'
import useCommonDict from './useCommonDict'
import { useOrgChainsQuery } from '../api/react-query/org'
import { sortByName } from '../helper'

export default function useChains (data, options) {
  const successCallback = useCallback(data => [...sortByName(data?.result || [])], [])
  return useCommonDict({
    errorMessage: errors.chains,
    queryMethod: useOrgChainsQuery,
    queryBody: data,
    queryOptions: options,
    successCallback
  })
}
