import React, { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, Form, message, Upload, Modal, Tooltip } from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
// import { observer } from 'mobx-react'
import env from '../../../env.json'
import { maxSizeUploadFileMB } from '../../../Constants'
import { AppConfig } from '../../../AppConfig'
import { isValidFileSize, isValidFileType, toLowerFirst } from '../../../helper'
import { profileScreen } from '../../../translates'
import './FormUploadImage.css'
import MedicalDocModal, { medicalDocTypes } from '../PhotoDoc/MedicalDocModal'

const normFile = e => {
  // console.log('Upload event:', e)
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList?.filter(file => !!file.status)
}
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const FormUploadImage = ({
  item,
  required,
  disabled,
  onUpdate,
  onUploading = () => {},
  onDelete,
  onlyJpegForPassport = false,
  markPassportRegBlock = false,
  onExtraRegIconClick = () => {},
  onInnIconClick = () => {}
}, ref) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [uploaded, setUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [labelModalType, setLabelModalType] = useState(null)

  const handleCancel = () => setPreviewVisible(false)

  const maxSize = useMemo(() => maxSizeUploadFileMB[item.name] || maxSizeUploadFileMB.default, [item])
  const uploadTypeList = useMemo(() => {
    const passportList = [
      profileScreen.input.passportPhoto1.name,
      profileScreen.input.passportPhoto2.name,
      profileScreen.input.passportPhoto3.name
    ]
    return onlyJpegForPassport && passportList.includes(item?.name)
      ? { jpeg: AppConfig.uploadTypes.jpeg }
      : AppConfig.uploadTypes
  }, [onlyJpegForPassport, item])

  const handlePreview = async file => {
    if (file.size > maxSize * 1024 * 1024) return
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewVisible(true)
    setPreviewImage(file.url || file.preview)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const uploadProps = {
    accept: Object.values(uploadTypeList).join(', '),
    name: 'file',
    method: 'PUT',
    action: `${env.apiEndpoint}partner/file${item?.name ? `?documentType=${item.name}` : ''}`,
    headers: {
      'X-Request-ID': uuidv4()
    },
    beforeUpload: file => {
      if (!isValidFileSize(file.size, maxSize)) {
        message.error(
          file?.size === 0
            ? 'Размер файла должен быть больше 0 МБ'
            : `Превышен максимальный размер файла ${maxSize} МБ.`
        )
      }
      if (!isValidFileType(file.type, uploadTypeList)) {
        message.error(`${file.name} имеет неверный формат (доступно: ${Object.keys(uploadTypeList).join(', ')})`)
      }
      return isValidFileSize(file.size, maxSize) && isValidFileType(file.type, uploadTypeList)
    },
    onPreview: handlePreview,
    onChange: (info) => {
      setUploaded(false)
      setUploading(true)

      if (info.file.status === AppConfig.uploadFileStatuses.uploading) {
        onUploading(item, info)
      }

      if (info.file.status !== AppConfig.uploadFileStatuses.uploading && !info.fileList?.length) {
        /**
         * удаление картинки
         */
        setUploading(false)
        onDelete(item)
      } else if (!isValidFileSize(info.file.size, maxSize) || !isValidFileType(info.file.type, uploadTypeList)) {
        setUploading(false)
        return
      }

      if (info.file.status === AppConfig.uploadFileStatuses.done) {
        setUploaded(true)
        setUploading(false)
        onUpdate(item, info)
      } else if (info.file.status === AppConfig.uploadFileStatuses.error) {
        setUploading(false)
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }

  const handleIconQuestionClick = (event) => {
    event.preventDefault()
    onExtraRegIconClick(item, uploading)
  }

  const handleIconInnClick = (event) => {
    event.preventDefault()
    onInnIconClick()
  }

  const handleLmkAllowanceClick = (event) => {
    event.preventDefault()
    setLabelModalType(medicalDocTypes.lmkAllowancePhoto)
  }

  const handleLmkAttestationClick = (event) => {
    event.preventDefault()
    setLabelModalType(medicalDocTypes.lmkAttestationPhoto)
  }

  const handleCloseLabelModal = () => setLabelModalType(null)

  const getFileLabel = (item) => {
    const hint = item?.hint
      ? (<><br /><span style={{ fontSize: 'small' }}>({toLowerFirst(item?.hint)})</span></>)
      : null

    switch (item?.name) {
      case profileScreen.input.passportPhotoExtraReg.name:
        return <>{item?.label} <QuestionCircleOutlined onClick={handleIconQuestionClick} style={{ marginLeft: 4 }} /></>
      case profileScreen.input.innPhoto.name:
        return <>{item?.label} <QuestionCircleOutlined onClick={handleIconInnClick} style={{ marginLeft: 4 }} /></>
      case profileScreen.input.lmkMainPhoto.name:
        return <div><b>{item?.label}</b>{hint}</div>
      case profileScreen.input.lmkAllowancePhoto.name:
        return <div><b>{item?.label}</b> <QuestionCircleOutlined onClick={handleLmkAllowanceClick} style={{ marginLeft: 4 }} />{hint}</div>
      case profileScreen.input.lmkAttestationPhoto.name:
        return <div><b>{item?.label}</b> <QuestionCircleOutlined onClick={handleLmkAttestationClick} style={{ marginLeft: 4 }} />{hint}</div>
      case profileScreen.input.trusteeConfirmation.name:
        return (
          <>
            {item?.label}
            <Tooltip placement='top' title={profileScreen.input.trusteeConfirmation.hint} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}>
              <QuestionCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        )
      case profileScreen.input.legalCapacityConfirmation.name:
        return (
          <>
            {item?.label}
            <Tooltip placement='top' title={profileScreen.input.legalCapacityConfirmation.hint} trigger='click' color='white' overlayInnerStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}>
              <QuestionCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          </>
        )
      default:
        return item?.label
    }
  }

  return (
    <>
      <Form.Item
        className={markPassportRegBlock && 'passport-reg'}
        name={item.name}
        label={getFileLabel(item)}
        rules={[{ required: required, message: item.message }]}
        valuePropName='fileList'
        getValueFromEvent={normFile}
      >

        <Upload {...uploadProps} className='full-width' listType='picture'>
          <Button
            block
            size='large'
            disabled={disabled || uploaded || uploading}
            ref={ref}
            icon={<UploadOutlined />}
            type='primary'
            ghost
          >
            {item.placeholder}
          </Button>
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <MedicalDocModal
        type={labelModalType}
        visible={Boolean(labelModalType)}
        onCancel={handleCloseLabelModal}
      />
    </>
  )
}
export default React.forwardRef(FormUploadImage)
