import { api, queryClient } from './api'
import { useQuery, useMutation } from 'react-query'

export const ASSETS_KEY = 'assets'
export const ACTIVE_LIST_KEY = 'activeList'

const fetchAssetsList = ({ queryKey }) => {
  const [/* key */, pagination] = queryKey
  return api.post({ path: `${ASSETS_KEY}/list`, data: pagination })
}
const fetchAssetsActiveList = () => {
  return api.get({ path: `${ASSETS_KEY}/list/active` })
}
const fetchAssetCreate = data => {
  return api.put({ path: `${ASSETS_KEY}`, data })
}
const fetchUserActivate = id => {
  return api.post({ path: `${ASSETS_KEY}/${id}/activate` })
}
const fetchUserArchive = id => {
  return api.post({ path: `${ASSETS_KEY}/${id}/archive` })
}

export function useGetAssetsListQuery (data, options) {
  return useQuery([ASSETS_KEY, data], fetchAssetsList, options)
}
export function useGetAssetsActiveListQuery (options) {
  return useQuery([ACTIVE_LIST_KEY], fetchAssetsActiveList, options)
}
export function useMutateAssetCreate () {
  return useMutation(fetchAssetCreate, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries(ASSETS_KEY)
    }
  })
}
export function useMutateUserActivate () {
  return useMutation(fetchUserActivate, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries([ASSETS_KEY])
    }
  })
}
export function useMutateUserArchive () {
  return useMutation(fetchUserArchive, {
    onSuccess: (data, id) => {
      queryClient.refetchQueries([ASSETS_KEY])
    }
  })
}
