import React, { useMemo, useState, useContext } from 'react'
import { Checkbox, Divider, Modal, Tooltip } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { arrayEquals } from '../../../helper'
import { ConfigContext } from '../../../context/configContext'
import { partnerListColKeys } from '../../../Constants'
import { AppConfig } from '../../../AppConfig'

const initialDisableKeys = {
  defaultGroup: [partnerListColKeys.inn, partnerListColKeys.lmkFormStatuses, partnerListColKeys.fireBriefingFormStatuses],
  [AppConfig.groups.supervisors]: [partnerListColKeys.inn],
  [AppConfig.groups.citymanagers]: [partnerListColKeys.inn, partnerListColKeys.lmkFormStatuses],
  [AppConfig.groups.personalmanagers]: [
    partnerListColKeys.inn,
    // partnerListColKeys.formStatuses,
    partnerListColKeys.profileFormStatuses,
    partnerListColKeys.lmkFormStatuses,
    partnerListColKeys.cardStatuses,
    partnerListColKeys.payoutSuspended
  ],
  [AppConfig.groups.contentcoordinators]: [
    partnerListColKeys.inn,
    partnerListColKeys.lmkFormStatuses,
    partnerListColKeys.cardStatuses,
    partnerListColKeys.roles,
    partnerListColKeys.cityIds,
    partnerListColKeys.storeName,
    partnerListColKeys.offerExpires,
    partnerListColKeys.verificationStatuses,
    partnerListColKeys.profileFormStatuses
    // partnerListColKeys.formStatuses
  ]
}

function TableSettings ({ columnsKey, options, onSave }) {
  const { user } = useContext(ConfigContext)
  const initialOptions = useMemo(() => options.map(item => item.value), [options])

  const [checkedColumns, setCheckedColumns] = useState(JSON.parse(
    localStorage.getItem(columnsKey)) ||
    initialOptions.filter(o => ![...(initialDisableKeys[user?.groupName] || initialDisableKeys.defaultGroup)].includes(o))
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isModified = useMemo(() => !arrayEquals(initialOptions, checkedColumns), [initialOptions, checkedColumns])

  const onCheckAllChange = e => {
    setCheckedColumns(e.target.checked ? initialOptions : [])
  }

  const onModalClose = () => {
    setIsModalOpen(false)
    if (!checkedColumns.length) {
      setCheckedColumns(initialOptions)
    }
  }

  const onOptionsSave = () => {
    onSave(checkedColumns)
    localStorage.setItem(columnsKey, JSON.stringify(checkedColumns))
    setIsModalOpen(false)
  }

  return (
    <div>
      <Tooltip placement='left' title='Изменить настройки таблицы'>
        <SettingOutlined className={`table-settings ${isModified ? 'modified' : ''}`} onClick={() => setIsModalOpen(true)} />
      </Tooltip>
      <Modal
        title='Настройки таблицы'
        visible={isModalOpen}
        onCancel={onModalClose}
        onOk={onOptionsSave}
        okButtonProps={{ disabled: !checkedColumns.length }}
      >
        <Checkbox indeterminate={!!checkedColumns.length && isModified} onChange={onCheckAllChange} checked={!isModified}>
          Выбрать все
        </Checkbox>
        <Divider />
        <Checkbox.Group
          className='settings'
          options={options}
          value={checkedColumns}
          onChange={setCheckedColumns}
        />
      </Modal>
    </div>
  )
}

export default TableSettings
