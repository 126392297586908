import env from './env.json'
import { ADMIN_AUTH_PAGE } from './routes'
import { ALL } from './Constants'
import { errors, profileScreen } from './translates'
import moment from 'moment-timezone'
import { getListByFileId } from './Store/PartnerProfile/Model'
import { AppConfig } from './AppConfig'
import { formatForApiVehicleNumber } from './components/Common/FormItems/VehicleNumberFormItem'

export const prepareDate = date => {
  if (!date) return ''
  let today = new Date(date)
  let dd = today.getDate()

  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  today = `${dd}.${mm}.${yyyy}`
  return today
}

export const toServerUtcDate = date => {
  if (!date) return ''
  if (!moment(date, 'DD.MM.YYYY', true).isValid()) return null
  const d = date.split('.').reverse().join('-')
  return new Date(d).toUTCString()
}

export const makeFileUrl = (f, full = true) => `${full ? env.apiEndpoint : ''}admin/partners/file/${f}`

export const digestMessage = async (message) => {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  // convert bytes to hex string
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

export const adminResponseHandler = r => {
  if (r.status === 401) {
    window.location.href = ADMIN_AUTH_PAGE
    return Promise.resolve({
      errorMessage: 'Сессия завершена'
    })
  }
  return r.json()
}

// export function groupBy (list, prop) {
//   return Array.isArray(list)
//     ? list.reduce((groups, item) => {
//       const group = (groups[item[prop]] || [])
//       group.push(item)
//       groups[item[prop]] = group
//       return groups
//     }, {})
//     : {}
// }
/**
 * It updates the state with the new value and returns the updated state
 * @param {Object} state
 * @param {Object} value
 * @return {Object}
 */
export function simpleReducer (state, value) {
  return {
    ...state,
    ...value
  }
}

export function getEnvironmentByHost () {
  switch (true) {
    case window.location.host === 'dev.sberlogistics.improvity.ru':
      return AppConfig.env.development
    case window.location.host === 'sberlogistics.gw-stage.sbermarket.tech':
      return AppConfig.env.stage
    case window.location.host.includes('localhost'):
      return AppConfig.env.localhost
    default: return null
  }
}

export const isDev = () => getEnvironmentByHost() === AppConfig.env.development
export const isStage = () => getEnvironmentByHost() === AppConfig.env.stage
export const isLocal = () => getEnvironmentByHost() === AppConfig.env.localhost
export const isProd = () => ![
  AppConfig.env.development,
  AppConfig.env.stage,
  AppConfig.env.localhost
].includes(getEnvironmentByHost())

export function getSentryOptions () {
  const environment = getEnvironmentByHost()
  if (!environment) return {}
  return {
    dsn: 'https://b07ae5ac936843e2927237a7e97f57b0@sentry.improvity.ru/3',
    environment
  }
}

export function sortByName (list) {
  return list.sort((a, b) => {
    const x = a?.name?.toLowerCase()
    const y = b?.name?.toLowerCase()
    return x < y ? -1 : x > y ? 1 : 0
  })
}

export function sortByParam (list, param) {
  return list.sort((a, b) => {
    const x = a?.[param]?.toLowerCase()
    const y = b?.[param]?.toLowerCase()
    return x < y ? -1 : x > y ? 1 : 0
  })
}

/**
 * @typedef {Object} Scope
 * @property {String} id
 * @property {String} name
 * @property {[Object]} [chains]
 * @property {String} [chains.id]
 * @property {String} [chains.name]
 * @property {[Object]} [chains.stores]
 * @property {String} [chains.stores.id]
 * @property {String} [chains.stores.name]
 *
 */

/**
 * Создает массив выбрааных элементов дерева для компонента antd: Tree
 * @param {[Scope]} cities магазины из области видимости пользователя
 * [{
 *     "chains": [
 *         {
 *             "stores": [
 *                 {
 *                     "id": "6391673a9d144be9a7e968921a2704fe",
 *                     "name": "ул. Ленина, д. 132"
 *                 }
 *             ],
 *             "id": "bd2f5d22b2554fe8bdaa19403ba77238",
 *             "name": "Лента1"
 *         }
 *     ],
 *     "id": "c247bf9b2b4348549e3652a7667d3fc1",
 *     "name": "Альметьевск"
 * }]
 * @param {[Scope]} orgTree магазины из орг структуры
 *
 * @return {[String]} - [String]
 *          String='id' выбран весь город со все вложенностью (id города)
 *          String='id,id' выбрана вся сеть со все вложенностью (id города, id сети)
 *          String='id,id,id' выбран конкретный магазин (id города, id сети, id магазина)
 */
export function createCheckedKeysForOrgTree (cities, orgTree) {
  return cities?.reduce((p, c) => {
    const originCity = orgTree?.find(e => e.id === c.id)
    const countStores = {
      inCity: 0,
      inOriginCity: 0
    }
    c?.chains?.reduce((pChain, cChain) => {
      const originChain = originCity?.chains?.find(e => e.id === cChain.id)
      // выбраны все магазины в сети значит выбираем сеть
      if (cChain?.stores?.length > 0 && cChain?.stores?.length === originChain?.stores?.length) {
        pChain.push([c.id, cChain.id].join(','))
      }
      countStores.inCity += cChain?.stores?.length || 0
      countStores.inOriginCity += originChain?.stores?.length || 0

      cChain?.stores?.reduce((pStore, cStore) => {
        pStore.push([c.id, cChain.id, cStore.id].join(','))
        return p
      }, p)
      return p
    }, p)
    // выбраны все магазины во всех сетях значит выбираем город
    // или пустой город без сетей
    if ((c?.chains?.length === originCity?.chains?.length &&
      countStores.inCity > 0 &&
      countStores.inCity === countStores.inOriginCity) || !originCity?.chains?.length) {
      p.push(c.id)
    }
    return p
  }, [])
}

/**
 * @typedef {Object} NewScope
 * @property {String} id
 * @property {[Object]} chains
 * @property {String} [chains.id]
 * @property {[Object]} [chains.stores]
 * @property {String} [chains.stores.id]
 */

/**
 * Создает обьект области видимости пользователя из массива выбранных элементов
 * @param {[String]} checkedKeys - [String]
 *          String='id' выбран весь город со все вложенностью (id города)
 *          String='id,id' выбрана вся сеть со все вложенностью (id города, id сети)
 *          String='id,id,id' выбран конкретный магазин (id города, id сети, id магазина)
 *
 * @return {[NewScope]}
 */
export function createUserScopeFromCheckedKeys (checkedKeys) {
  const dataByStore = checkedKeys.filter(e => e !== ALL && e.split(',').length === 3).reduce((p, c) => {
    const [cityId, chainId, storeId] = c.split(',')
    const cityIndex = p.findIndex(city => city.id === cityId)
    const chainIndex = p[cityIndex]?.chains?.findIndex(chain => chain.id === chainId) ?? -1
    const storeIndex = p[cityIndex]?.chains?.[chainIndex]?.stores.findIndex(store => store.id === storeId) ?? -1
    const updatedStores = {
      id: storeId
    }
    const updatedChain = {
      id: chainId,
      stores: [
        ...(p[cityIndex]?.chains?.[chainIndex]?.stores?.slice(0, Math.max(0, storeIndex)) || []),
        updatedStores,
        ...(p[cityIndex]?.chains?.[chainIndex]?.stores?.slice(storeIndex + 1) || [])
      ]
    }
    const updatedCity = {
      id: cityId,
      chains: [
        ...(p[cityIndex]?.chains?.slice(0, Math.max(0, chainIndex)) || []),
        updatedChain,
        ...(p[cityIndex]?.chains?.slice(chainIndex + 1) || [])
      ]
    }
    return [
      ...p.slice(0, Math.max(0, cityIndex)), // экранируем -1
      updatedCity,
      ...p.slice(cityIndex + 1)
    ]
  }, [])
  // город без сетей (единственный ИД) будет содержаться в единственном варианте в списке
  const onlyCities = checkedKeys.filter(e => e.split(',').length === 1).reduce((p, cityId) => {
    if (checkedKeys.filter(keys => keys.includes(cityId)).length === 1) {
      p.push({
        id: cityId,
        chains: []
      })
    }
    return p
  }, [])
  return [...onlyCities, ...dataByStore]
}

const patternDigits = /\d/
const patternDate = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/
const patternTitleRus = /^[-,.'№()"/<>0-9ёа-я\s]+$/i
const patternName = /^[-.'ёа-я\s]+$/i
const patternNameRus = /^[-ёа-я\s]+$/i
export const patterns = {
  date: patternDate,
  title: /^[-,.'№()"/<>0-9A-zёа-я\s]+$/i,
  titleRus: patternTitleRus,
  name: patternName,
  nameRus: patternNameRus,
  bankBik: /04[0-9]{7}$/,
  bankAccount: /408[0-9]{17}$/,
  inn: /[0-9]{12}$/,
  snils: /^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/,
  drivingNumber: /^[0-9]{2} [0-9]{2} [0-9]{6}$/,
  phone: /\+7[0-9]{10}$/,
  email: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
  uid: /^[A-zёа-я0-9]+$/i,
  letterDigit: /^[A-zёа-я0-9 ]+$/i,
  birthDate: patternDate,
  'passport.number': /^[0-9]{6}$/,
  'registrationAddress.zipCode': /^[0-9]{6}$/,
  'registrationAddress.country': patternName,
  'registrationAddress.region': patternTitleRus,
  'registrationAddress.district': patternTitleRus,
  'registrationAddress.city': patternTitleRus,
  'registrationAddress.settlement': patternTitleRus,
  'registrationAddress.street': patternTitleRus,
  'registrationAddress.house': patternTitleRus,
  'registrationAddress.building': patternTitleRus,
  'registrationAddress.apartment': patternTitleRus,
  'passport.series': /^[0-9]{4}$/,
  'passport.issuerCode': /^[0-9]{3}-[0-9]{3}$/,
  'passport.issueDate': patternDate,
  'passport.issuerName': patternTitleRus,
  'drivingLicence.issueDate': patternDate,
  placeOfBirth: patternTitleRus,
  surname: patternName,
  firstName: patternName,
  middleName: patternName,
  medicalBookNumber: patternDigits,
  examinationDate: patternDate,
  attestationDate: patternDate
}
export const validator = ({ name, rule, value }) => {
  const pattern = patterns[name]
  if (!pattern || (!rule.required && !value?.length)) {
    return Promise.resolve()
  }
  if (name === 'inn') {
    return value && pattern?.test(value) && value.length < 13 ? Promise.resolve() : Promise.reject(rule?.message)
  } else if (['date'].includes(name)) {
    return value && pattern?.test(value) && moment(value, 'DD.MM.YYYY', true).isValid() ? Promise.resolve() : Promise.reject(rule?.message)
  } else if (name === 'name') {
    return value?.trim()?.length && pattern?.test(value?.trim()) ? Promise.resolve() : Promise.reject(rule?.message)
  } else {
    return value && pattern?.test(value) ? Promise.resolve() : Promise.reject(rule?.message)
  }
}

export const validatePassportNumber = (rule, value) => validator({ rule, value, name: 'passport.number' })
export const validatePassportSeries = (rule, value) => validator({ rule, value, name: 'passport.series' })
export const validatePassportIssuerCode = (rule, value) => validator({ rule, value, name: 'passport.issuerCode' })
export const validateDate = (rule, value) => validator({ rule, value, name: 'date' })
export const validateTitle = (rule, value) => validator({ rule, value, name: 'title' })
export const validateTitleRus = (rule, value) => validator({ rule, value, name: 'titleRus' })
export const validateName = (rule, value) => validator({ rule, value, name: 'name' })
export const validateNameRus = (rule, value) => validator({ rule, value, name: 'nameRus' })
export const validateBankBik = (rule, value) => validator({ rule, value, name: 'bankBik' })
export const validateBankAccount = (rule, value) => validator({ rule, value, name: 'bankAccount' })
export const validateInn = (rule, value) => validator({ rule, value, name: 'inn' })
export const validateSnils = (rule, value) => validator({ rule, value, name: 'snils' })
export const validateDrivingNumber = (rule, value) => validator({ rule, value, name: 'drivingNumber' })
export const validatePhone = (rule, value) => validator({ rule, value, name: 'phone' })
export const validateEmail = (rule, value) => validator({ rule, value, name: 'email' })
export const validateBankAccountIndividual = (rule, value) => {
  if (!value) return Promise.resolve()
  return value.startsWith('40802') ? Promise.resolve() : Promise.reject(errors.bankAccount)
}
export const validateBankAccountSelfEmployed = (rule, value) => {
  if (!value) return Promise.resolve()
  return value.startsWith('40802') ? Promise.reject(errors.bankAccount) : Promise.resolve()
}

export const validateDate1BeforeDate2 = ({ rule, date1, date2 }) => {
  const shortDate = AppConfig.formats.shortDate
  // когда входные параметры невалидные, то не показывать ошибку, до момента когда их полностью введут
  if (moment(date1, shortDate, true).isValid() && moment(date2, shortDate, true).isValid()) {
    const moment1 = moment(date1, shortDate, true)
    const moment2 = moment(date2, shortDate, true)
    return moment1.isBefore(moment2) ? Promise.resolve() : Promise.reject(rule.message)
  }
  return Promise.resolve()
}

export const validateDateNotFuture = ({ rule, date }) => {
  const shortDate = AppConfig.formats.shortDate
  // когда входные параметры невалидные, то не показывать ошибку, до момента когда их полностью введут
  if (moment(date, shortDate, true).isValid()) {
    const momentDate = moment(date, shortDate, true)
    const momentNow = moment()
    return momentDate.isBefore(momentNow) ? Promise.resolve() : Promise.reject(rule.message)
  }
  return Promise.resolve()
}

export const validateAllPhotosUploading = (values) => {
  const isUploading = AppConfig.documentNameList.some(
    (x) => values?.[x]?.[0]?.status === AppConfig.uploadFileStatuses.uploading
  )
  return !isUploading
}

export const isValidSnils = (snils) => {
  if (snils?.length === 11) {
    let checksum = 0
    for (let i = 0; i < 9; i++) {
      checksum += parseInt(snils?.charAt(i)) * (9 - i)
    }
    if (checksum > 101) {
      checksum = checksum % 101
    }
    if (checksum === 100 || checksum === 101) {
      checksum = 0
    }
    return checksum === parseInt(snils?.substring(9))
  }
  return false
}

export const validateFullSnils = ({ rule, snils }) => {
  /** валидные снилс snils
   * 19197706823
   * 34613152751
   * 83897945198
   */
  const clearSnils = String(snils).replace(/[^0-9]+/g, '')
  if (clearSnils?.length !== 11) {
    return Promise.resolve()
  }

  const res = isValidSnils(clearSnils)
  return res ? Promise.resolve() : Promise.reject(rule?.message)
}

export const validateFullInn = ({ rule, inn }) => {
  /** валидные инн inn
   * 940300226626
   * 500510172836
   * 370404901388
   * 260198805535
   * 772479397211
   * 503235114774
   * ------------
   */
  if (inn?.length !== 12) {
    return Promise.resolve()
  }
  const checkDigit = function (inn, coefficients) {
    let n = 0
    for (const i in coefficients) {
      n += coefficients[i] * inn[i]
    }
    return parseInt(n % 11 % 10)
  }
  const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
  const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
  const res = (n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))
  return res ? Promise.resolve() : Promise.reject(rule?.message)
}

export const validateFullBankAccount = ({ rule, account, bik }) => {
  /**
   * валидные счет и бик ИП
   * 40802564984984987984 - 046516981
   * 40802810700003928233 - 044525974
   * 40802810902000032986 - 040173604
   * 40802810000004012055 - 044525974
   * 40802810000000007103 - 044525974
   * 40802810500003795705 - 044525974
   * 40802810100002985039 - 044525974
   * 40802810200002410297 - 044525974
   * 40802810902500151171 - 044525999
   * 40802810201500374442 - 044525999
   * 40802810932410009154 - 044030786
   *
   * валидные счет и бик СЗ
   * 40817810238042202892 - 044525225
   * 40817810938124314050 - 044525225
   * 40817810072005610758 - 047501602
   * 40817810900046950749 - 044525974
   *
   * невалидные
   * 40802564984984987985 - 046516982
   */
  // Справочное значение из задачи
  const digitWeight = '71371371371371371371371'
  if (account?.length !== 20 || bik?.length !== 9) {
    /**
     * Когда входные параметры еще не готовы для расчета контрольной суммы кидаем положительный результат
     * чтобы не выводить ошибку данной валидации раньше чем это нужно
     */
    return Promise.resolve()
  }

  // В зависимости от некоторых символов БИК полуаем префикс из БИК
  const prefix = bik.substring(6, 8) === '00'
    ? '0' + bik.substring(4, 6)
    : bik.substring(bik.length - 3)

  const bikAccount = prefix + account
  // Вычисляем контрольную сумму на основе строки префиксБИКсчет и строки весов каждого разряда
  let controlSum = 0
  for (let index = 0; index < bikAccount.length; index++) {
    controlSum += bikAccount[index] * digitWeight[index]
  }
  // console.log('controlSum', controlSum, controlSum % 10 === 0)
  return controlSum % 10 === 0 ? Promise.resolve() : Promise.reject(rule?.message)
}

export const getFileId = (values, key) => values?.[key]?.length ? values[key][0]?.response?.fileId : undefined

export const createApiDataFromForm = values => Object.fromEntries(Object.entries({
  personalData: {
    ...values.personalData,
    ...(values.personalData?.[profileScreen.input.vehicleNumber.name]?.length
      ? {
        [profileScreen.input.vehicleNumber.name]: formatForApiVehicleNumber(
          values.personalData[profileScreen.input.vehicleNumber.name],
          values.personalData[profileScreen.input.isForeignVehicleNumber.name]
        )
      }
      : { [profileScreen.input.vehicleNumber.name]: undefined }
    ),
    passport: {
      ...values.personalData?.passport || {},
      issueDate: toServerUtcDate(values.personalData?.passport?.issueDate)
    },
    drivingLicence: {
      ...values.personalData?.drivingLicence || {},
      seriesNumber: values.personalData?.drivingLicence?.seriesNumber?.replace(/[^0-9]+/g, ''),
      issueDate: toServerUtcDate(values.personalData?.drivingLicence?.issueDate)
    },
    snils: values.personalData?.snils?.replace(/[^0-9]+/g, ''),
    birthDate: toServerUtcDate(values.personalData?.birthDate),
    ...(values?.personalData?.firstName?.trim()?.length ? { firstName: values?.personalData?.firstName?.trim() } : {}),
    ...(values?.personalData?.surname?.trim()?.length ? { surname: values?.personalData?.surname?.trim() } : {}),
    ...(values?.personalData?.middleName?.trim()?.length ? { middleName: values?.personalData?.middleName?.trim() } : {})
  },
  bankDetails: {
    ...values.bankDetails,
    bankDetailsPhoto: getFileId(values, 'bankDetailsPhoto')
  },
  innDetails: {
    ...values.innDetails
  },
  lmkDetails: {
    ...values.lmkDetails,
    examinationDate: toServerUtcDate(values.lmkDetails?.examinationDate),
    attestationDate: toServerUtcDate(values.lmkDetails?.attestationDate)
  },
  ...(values?.fireBriefingDetails && {
    fireBriefingDetails: {
      ...values.fireBriefingDetails,
      briefingDate: toServerUtcDate(values.fireBriefingDetails.briefingDate)
    }
  }),
  ...(values?.requisitesDetails && {
    requisitesDetails: {
      ...values.requisitesDetails,
      bankRequisitesPhoto: getFileId(values, 'bankRequisitesPhoto')
    }
  }),
  trusteeAgreement: getFileId(values, 'trusteeAgreement'),
  trusteeConfirmation: getFileId(values, 'trusteeConfirmation'),
  legalCapacityConfirmation: getFileId(values, 'legalCapacityConfirmation'),
  trusteePassport: getFileId(values, 'trusteePassport'),
  passportPhoto1: getFileId(values, 'passportPhoto1'),
  passportPhoto2: getFileId(values, 'passportPhoto2'),
  passportPhoto3: getFileId(values, 'passportPhoto3'),
  passportPhotoTmpReg: getFileId(values, 'passportPhotoTmpReg'),
  passportPhotoExtraReg: getFileId(values, 'passportPhotoExtraReg'),
  innPhoto: getFileId(values, 'innPhoto'),
  mnregPhoto: getFileId(values, 'mnregPhoto'),
  egripPhoto: getFileId(values, 'egripPhoto'),
  vaccinacionPhoto: getFileId(values, 'vaccinacionPhoto'),
  snilsPhoto: getFileId(values, 'snilsPhoto'),
  drivingLicencePhoto1: getFileId(values, 'drivingLicencePhoto1'),
  drivingLicencePhoto2: getFileId(values, 'drivingLicencePhoto2'),
  lmkMainPhoto: getFileId(values, 'lmkMainPhoto'),
  lmkAllowancePhoto: getFileId(values, 'lmkAllowancePhoto'),
  lmkAttestationPhoto: getFileId(values, 'lmkAttestationPhoto'),
  notFormerCivilEmployee: values.notFormerCivilEmployee
}).filter(([key]) => Object.prototype.hasOwnProperty.call(values, key)))

export const formatDateForFrontend = (date) => {
  if (patterns.date.test(date)) {
    return date
  }
  return date ? moment(date).format('DD.MM.yyyy') : ''
}

const getFile = (file, prepareForForm = true) => prepareForForm ? getListByFileId(file) : file

export const createFormDataFromApi = (values, prepareFiles = true) => Object.fromEntries(Object.entries({
  ...values,
  personalData: {
    ...values?.personalData,
    passport: {
      ...values?.personalData?.passport,
      issueDate: formatDateForFrontend(values?.personalData?.passport?.issueDate)
    },
    birthDate: formatDateForFrontend(values?.personalData?.birthDate),
    drivingLicence: {
      ...values?.personalData?.drivingLicence,
      issueDate: formatDateForFrontend(values?.personalData?.drivingLicence?.issueDate)
    }
  },
  form: {
    ...values?.form,
    personalDetails: {
      ...values?.form?.personalDetails,
      passport: {
        ...values?.form?.personalDetails?.passport,
        issueDate: formatDateForFrontend(values?.form?.personalDetails?.passport?.issueDate)
      },
      drivingLicence: {
        ...values?.form?.personalDetails?.drivingLicence,
        issueDate: formatDateForFrontend(values?.form?.personalDetails?.drivingLicence?.issueDate)
      }
    }
  },
  bankDetails: {
    ...values?.bankDetails,
    bankDetailsPhoto: getFile(values?.bankDetails?.bankDetailsPhoto, prepareFiles)
  },
  ...(values?.lmkDetails && {
    lmkDetails: {
      ...values.lmkDetails,
      examinationDate: formatDateForFrontend(values.lmkDetails.examinationDate),
      examinationExpireDate: formatDateForFrontend(values.lmkDetails.examinationExpireDate),
      attestationDate: formatDateForFrontend(values.lmkDetails.attestationDate),
      attestationExpireDate: formatDateForFrontend(values.lmkDetails.attestationExpireDate)
    }
  }),
  ...(values?.fireBriefingDetails && {
    fireBriefingDetails: {
      ...values.fireBriefingDetails,
      briefingDate: formatDateForFrontend(values.fireBriefingDetails.briefingDate)
    }
  }),
  ...(values?.requisitesDetails && {
    requisitesDetails: {
      ...values.requisitesDetails,
      requisitesBankPhoto: getFile(values?.requisitesDetails?.requisitesBankPhoto, prepareFiles)
    }
  }),
  passportPhoto1: getFile(values?.passportPhoto1, prepareFiles),
  passportPhoto2: getFile(values?.passportPhoto2, prepareFiles),
  passportPhoto3: getFile(values?.passportPhoto3, prepareFiles),
  passportPhotoTmpReg: getFile(values?.passportPhotoTmpReg, prepareFiles),
  passportPhotoExtraReg: getFile(values?.passportPhotoExtraReg, prepareFiles),
  innPhoto: getFile(values?.innPhoto, prepareFiles),
  mnregPhoto: getFile(values?.mnregPhoto, prepareFiles),
  egripPhoto: getFile(values?.egripPhoto, prepareFiles),
  trusteeAgreement: getFile(values?.trusteeAgreement, prepareFiles),
  trusteeConfirmation: getFile(values?.trusteeConfirmation, prepareFiles),
  legalCapacityConfirmation: getFile(values?.legalCapacityConfirmation, prepareFiles),
  trusteePassport: getFile(values?.trusteePassport, prepareFiles),
  vaccinacionPhoto: getFile(values?.vaccinacionPhoto, prepareFiles),
  snilsPhoto: getFile(values?.snilsPhoto, prepareFiles),
  drivingLicencePhoto1: getFile(values?.drivingLicencePhoto1, prepareFiles),
  drivingLicencePhoto2: getFile(values?.drivingLicencePhoto2, prepareFiles),
  lmkMainPhoto: getFile(values?.lmkMainPhoto, prepareFiles),
  lmkAllowancePhoto: getFile(values?.lmkAllowancePhoto, prepareFiles),
  lmkAttestationPhoto: getFile(values?.lmkAttestationPhoto, prepareFiles)
}).filter(([key]) => Object.prototype.hasOwnProperty.call(values || {}, key)))

export const createApiDataFromFormWithFiles = (values, prepareFiles = true) => Object.fromEntries(Object.entries({
  ...values,
  personalData: {
    ...values.personalData,
    passport: {
      ...values?.personalData?.passport,
      issueDate: toServerUtcDate(values?.personalData?.passport?.issueDate)
    },
    birthDate: toServerUtcDate(values?.personalData?.birthDate),
    drivingLicence: {
      ...values?.personalData?.drivingLicence,
      issueDate: toServerUtcDate(values?.personalData?.drivingLicence?.issueDate)
    }
  },
  form: {
    ...values?.form,
    personalDetails: {
      ...values?.form?.personalDetails,
      passport: {
        ...values?.form?.personalDetails?.passport,
        issueDate: toServerUtcDate(values?.form?.personalDetails?.passport?.issueDate)
      },
      drivingLicence: {
        ...values?.form?.personalDetails?.drivingLicence,
        issueDate: toServerUtcDate(values?.form?.personalDetails?.drivingLicence?.issueDate)
      }
    }
  },
  bankDetails: {
    ...values.bankDetails,
    bankDetailsPhoto: getFile(values.bankDetails?.bankDetailsPhoto, prepareFiles)
  },
  passportPhoto1: getFile(values.passportPhoto1, prepareFiles),
  passportPhoto2: getFile(values.passportPhoto2, prepareFiles),
  passportPhoto3: getFile(values.passportPhoto3, prepareFiles),
  passportPhotoTmpReg: getFile(values.passportPhotoTmpReg, prepareFiles),
  passportPhotoExtraReg: getFile(values.passportPhotoExtraReg, prepareFiles),
  innPhoto: getFile(values.innPhoto, prepareFiles),
  mnregPhoto: getFile(values.mnregPhoto, prepareFiles),
  egripPhoto: getFile(values.egripPhoto, prepareFiles),
  trusteeAgreement: getFile(values.trusteeAgreement, prepareFiles),
  trusteeConfirmation: getFile(values.trusteeConfirmation, prepareFiles),
  legalCapacityConfirmation: getFile(values.legalCapacityConfirmation, prepareFiles),
  trusteePassport: getFile(values.trusteePassport, prepareFiles),
  vaccinacionPhoto: getFile(values.vaccinacionPhoto, prepareFiles)
}).filter(([key]) => Object.prototype.hasOwnProperty.call(values, key)))

export const trueFalseList = [
  { label: 'Да', value: true },
  { label: 'Нет', value: false }
]

/**
 * @param {number} number
 * @param {array} [words = ["яблоко", "яблока", "яблок"]]
 * @return {string}
 */
export const numberDeclension = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

export function arrayUniqValues (list = []) {
  return list.filter((item, pos) => list.indexOf(item) === pos)
}

export function arraySortByArray (array = [], sortOrder = [], key) {
  const existsItems = array.filter(item => sortOrder.includes(key ? item[key] : item))
  const notExistsItems = array.filter(item => !sortOrder.includes(key ? item[key] : item))
  existsItems.sort(function (a, b) {
    return sortOrder.indexOf(key ? a[key] : a) - sortOrder.indexOf(key ? b[key] : b)
  })
  return [...existsItems, ...notExistsItems]
}

export function arrayEquals (a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
}

export const ruCurrency = '\u20bd'

/**
 * @TODO Метод неправильно работает. Получает ключи только последнего уровня, все родительские ключи пропускает
 *
 {
    "user": {
        "key_value_map": {
            "CreatedDate": "123424",
            "Department": {
                "Name": "XYZ"
            }
        }
    }
 }
 результат без user
 {
    "key_value_map.CreatedDate": "123424",
    "key_value_map.Department.Name": "XYZ"
 }
 На исправление не было времени, плюс этот метод уже где-то используется
 */
export const flattenObject = (object = {}, keyPrefix) =>
  Object.keys(object).reduce((result, key) => {
    if (typeof object[key] === 'object' && object[key]) {
      result = {
        ...result,
        ...flattenObject(object[key], key)
      }
    } else {
      result[`${keyPrefix ? keyPrefix + '.' : ''}${key}`] = object[key]
    }
    return result
  }, {})

export const restoreFlattenObject = (object) =>
  Object.keys(object).reduce((result, key) => {
    key.split('.').reduce(
      (acc, e, i, keys) =>
        acc[e] ||
        (acc[e] = isNaN(Number(keys[i + 1]))
          ? keys.length - 1 === i
            ? object[key]
            : {}
          : []),
      result
    )
    return result
  }, {})

// Вместо flattenObject, который неправильно работает, но уже где-то работает.
export const flattenObject2 = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object' && obj[k]) Object.assign(acc, flattenObject2(obj[k], pre + k))
    else acc[pre + k] = obj[k]
    return acc
  }, {})

export const isValidFileSize = (fileSize, maxSize) => fileSize > 0 && fileSize <= maxSize * 1024 * 1024
export const isValidFileType = (fileType, uploadTypes) => Object.values(uploadTypes).includes(fileType)

export const getTargetsForDeclineReasons = () => {
  let list = { ...AppConfig.reasonTargetLabels }
  AppConfig.documentNameList.filter(
    item => ![
      profileScreen.input.mnregPhoto.name,
      profileScreen.input.lmkMainPhoto.name,
      profileScreen.input.lmkAllowancePhoto.name,
      profileScreen.input.lmkAttestationPhoto.name,
      profileScreen.input.innPhoto.name
    ].includes(item)
  ).forEach(doc => {
    list = {
      ...list,
      [doc]: profileScreen.input[doc]?.label ?? doc
    }
  })

  const validList = [
    'personalData',
    'bankDetails',
    'innDetails',
    profileScreen.input.passportPhoto1.name,
    profileScreen.input.passportPhoto2.name,
    profileScreen.input.snilsPhoto.name,
    profileScreen.input.drivingLicencePhoto1.name,
    profileScreen.input.drivingLicencePhoto2.name
  ]

  const resultList = {}
  Object.keys(list).forEach(key => {
    if (validList.includes(key)) {
      resultList[key] = list[key]
    }
  })

  return resultList
}

/**
 * В запросе accept корректирующих данных для одного поля посылается вся структура анкеты.
 * Чтобы не мусорить проводим чистку всей структуры. В запрос отправится только
 * одна ветка (конкретного поля) из всей структуры
 */
export const getOnlyInputFieldInPreparedData = (requestField, preparedData) => {
  const inputFlattened = flattenObject2(requestField)
  const inputFlattenedKey = Object.keys(inputFlattened)[0]
  const outputFlattened = flattenObject2(preparedData)
  Object.keys(outputFlattened).forEach(key => {
    if (key.substring(key.length - inputFlattenedKey.length) !== inputFlattenedKey) {
      delete outputFlattened[key]
    }
  })
  return restoreFlattenObject(outputFlattened)
}

export const showExcept = (role, roleList) => {
  return !(Array.isArray(roleList) && roleList.includes(role))
}

export const getPassportIssueDateRules = () => {
  return [
    {
      message: profileScreen.input.passportIssueDate.messageYear,
      validator: (rule, value) => validateDate1BeforeDate2({
        rule,
        date1: moment()
          .subtract(profileScreen.input.passportIssueDate.yearExpire, 'year')
          .format(AppConfig.formats.shortDate),
        date2: value
      })
    },
    {
      message: errors.futureDate,
      validator: (rule, value) => validateDateNotFuture({ rule, date: value })
    }
  ]
}

export const getBirthDateRules = () => {
  return [
    {
      message: profileScreen.input.birthDate.messageYear,
      validator: (rule, value) => validateDate1BeforeDate2({
        rule,
        date1: value,
        date2: moment()
          .subtract(profileScreen.input.birthDate.yearStart, 'year')
          .format(AppConfig.formats.shortDate)
      })
    },
    {
      message: profileScreen.input.birthDate.messageYear,
      validator: (rule, value) => validateDate1BeforeDate2({
        rule,
        date1: moment()
          .subtract(profileScreen.input.birthDate.yearEnd, 'year')
          .format(AppConfig.formats.shortDate),
        date2: value
      })
    }
  ]
}

export const isEmptyPassportData = (values) => {
  const inputs = profileScreen.input
  return !values?.personalData?.passport?.[inputs.passportSeries.name] ||
    !values?.personalData?.passport?.[inputs.passportNumber.name] ||
    !values?.personalData?.passport?.[inputs.passportIssuerName.name] ||
    !values?.personalData?.passport?.[inputs.passportIssuerCode.name] ||
    !values?.personalData?.passport?.[inputs.passportIssueDate.name]
}

export const getMedicalAcceptOfferErrors = (values) => {
  const inputs = profileScreen.input
  const errorLabels = [
    ...(!values?.personalData?.[inputs.surname.name] ? [inputs.surname.label] : []),
    ...(!values?.personalData?.[inputs.firstName.name] ? [inputs.firstName.label] : []),
    ...((
      !values?.personalData?.[inputs.withoutMiddleName.name] &&
      !values?.personalData?.[inputs.middleName.name]
    ) ? [inputs.middleName.label] : []),
    ...(!values?.personalData?.passport?.[inputs.passportSeries.name] ? [inputs.passportSeries.label] : []),
    ...(!values?.personalData?.passport?.[inputs.passportNumber.name] ? [inputs.passportNumber.label] : []),
    ...(!values?.personalData?.passport?.[inputs.passportIssuerName.name] ? [inputs.passportIssuerName.label] : []),
    ...(!values?.personalData?.passport?.[inputs.passportIssuerCode.name] ? [inputs.passportIssuerCode.label] : []),
    ...(!values?.personalData?.passport?.[inputs.passportIssueDate.name] ? [inputs.passportIssueDate.label] : []),
    ...(!values?.personalData?.registrationAddress?.[inputs.registrationAddressCountry.name] ? [inputs.registrationAddressCountry.label] : []),
    ...(!values?.personalData?.registrationAddress?.[inputs.registrationAddressZipCode.name] ? [inputs.registrationAddressZipCode.label] : []),
    ...(!values?.personalData?.registrationAddress?.[inputs.registrationAddressRegion.name] ? [inputs.registrationAddressRegion.label] : []),
    ...(!values?.personalData?.registrationAddress?.[inputs.registrationAddressStreet.name] ? [inputs.registrationAddressStreet.label] : []),
    ...(!values?.personalData?.registrationAddress?.[inputs.registrationAddressHouse.name] ? [inputs.registrationAddressHouse.label] : [])
  ]
  return errorLabels
}

export const isDisabledMedicalAcceptOffer = (values) => {
  const errors = getMedicalAcceptOfferErrors(values)
  return errors?.length > 0
}

export const getMedicalOfferParams = (profile) => {
  const inputs = profileScreen.input
  return {
    'Partner.Name': [
      profile?.personalData?.[inputs.surname.name],
      profile?.personalData?.[inputs.firstName.name],
      profile?.personalData?.[inputs.middleName.name]
    ].filter(Boolean).join(' '),
    'Partner.Passport.Series': [profile?.personalData?.passport?.[inputs.passportSeries.name]].filter(Boolean).join(''),
    'Partner.Passport.Number': [profile?.personalData?.passport?.[inputs.passportNumber.name]].filter(Boolean).join(''),
    'Partner.Passport.Issuer': [profile?.personalData?.passport?.[inputs.passportIssuerName.name]].filter(Boolean).join(''),
    'Partner.Passport.IssueDate': formatDateForFrontend(profile?.personalData?.passport?.[inputs.passportIssueDate.name]),
    'Partner.RegistrationAddress': [
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressCountry.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressZipCode.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressRegion.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressDistrict.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressCity.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressSettlement.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressStreet.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressHouse.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressBuilding.name],
      profile?.personalData?.registrationAddress?.[inputs.registrationAddressApartment.name]
    ].filter(Boolean).join(', ')
  }
}

export const renderMedicalOfferTemplate = (content, profile) => {
  if (content && profile) {
    let render = content
    const replaceList = getMedicalOfferParams(profile)
    Object.keys(replaceList).forEach(pattern => {
      render = render.replaceAll(`{{${pattern}}}`, replaceList[pattern])
    })
    return render
  }
  return content
}

export const handleMyTax = () => {
  window.open('https://lknpd.nalog.ru/', '_blank', 'noreferrer')
}

export const isEqualRegistrationKindCodes = (profileKindCode, formKindCode) => {
  const profileCode = profileKindCode?.replace('none', '') || ''
  const formCode = formKindCode?.replace('none', '') || ''
  return profileCode === formCode
}

export const dragElement = (el) => {
  // const initPosX = el.style.left
  // const initPosY = el.style.top
  let posX1 = 0; let posY1 = 0; let posX2 = 0; let posY2 = 0
  el.onmousedown = dragMouseDown

  function dragMouseDown (e) {
    e = e || window.event
    e.preventDefault()
    posX2 = e.clientX
    posY2 = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  function elementDrag (e) {
    e = e || window.event
    e.preventDefault()
    e.stopImmediatePropagation()
    posX1 = posX2 - e.clientX
    posY1 = posY2 - e.clientY
    posX2 = e.clientX
    posY2 = e.clientY
    el.style.top = (el.offsetTop - posY1) + 'px'
    el.style.left = (el.offsetLeft - posX1) + 'px'
  }

  function closeDragElement () {
    document.onmouseup = null
    document.onmousemove = null
    // el.style.top = initPosY
    // el.style.left = initPosX
  }
}

export function isMobile () {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function isShownSberIcon (profileAndSberIdProperty, profile) {
  if (!profile?.sberIdData) return false

  const flattenedObject = flattenObject2(profile)
  const blockName = profileAndSberIdProperty === 'inn' ? 'innDetails' : 'personalData'
  let valueProfile = flattenedObject[`${blockName}.${profileAndSberIdProperty}`]
  let valueSberIdData = flattenedObject[`sberIdData.${profileAndSberIdProperty}`]

  if (valueProfile === undefined) return false

  if (['passport.issueDate', 'birthDate', 'drivingLicence.issueDate'].includes(profileAndSberIdProperty)) {
    valueProfile = formatDateForFrontend(valueProfile)
    valueSberIdData = formatDateForFrontend(valueSberIdData)
  }

  if (valueProfile !== valueSberIdData) return false

  return true
}

export function isShownTinkoffIcon (profileAndTinkoffIdProperty, profile) {
  if (!profile?.tinkoffData) return false

  const flattenedObject = flattenObject2(profile)
  const blockName = profileAndTinkoffIdProperty === 'inn' ? 'innDetails' : 'personalData'
  let valueProfile = flattenedObject[`${blockName}.${profileAndTinkoffIdProperty}`]
  let valueTinkoffIdData = flattenedObject[`tinkoffData.${profileAndTinkoffIdProperty}`]

  if (valueProfile === undefined) return false

  if (['passport.issueDate', 'birthDate', 'drivingLicence.issueDate'].includes(profileAndTinkoffIdProperty)) {
    valueProfile = formatDateForFrontend(valueProfile)
    valueTinkoffIdData = formatDateForFrontend(valueTinkoffIdData)
  }

  if (valueProfile !== valueTinkoffIdData) return false

  return true
}

export function toLowerFirst (string) {
  if (typeof string === 'string' || string instanceof String) {
    return `${string[0].toLowerCase()}${string.substring(1)}`
  }
  return string
}

export function toUpperFirst (string) {
  if (typeof string === 'string' || string instanceof String) {
    return `${string[0].toUpperCase()}${string.substring(1)}`
  }
  return string
}

export function scrollToFormElement (pathName) {
  if (!Array.isArray(pathName)) return
  const element = document.getElementById(`basic_${pathName.join('_')}`)
  element?.parentElement?.scrollIntoView({ behavior: 'smooth' })
}

export function scrollToFirstError (errorList) {
  if (!Array.isArray(errorList)) return

  const cache = {}
  errorList.forEach(error => {
    const id = error?.errors?.length && `basic_${error?.name?.join('_')}`
    const el = document.getElementById(id)
    cache[id] = {
      top: el?.parentElement?.getBoundingClientRect()?.top,
      id
    }
  })

  setTimeout(() => {
    const firstId = Object.values(cache).sort(
      (a, b) => (a.top > b.top) ? 1 : ((b.top > a.top) ? -1 : 0)
    )[0]?.id

    if (firstId) {
      const element = document.getElementById(firstId)
      element?.parentElement?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, 50)
}

export function formatDrivingNumber (val) {
  return val?.replace(/^([0-9]{2})([0-9]{2})([0-9]{6})/g, '$1 $2 $3')
}
export function formatSnils (val) {
  return val?.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g, '$1-$2-$3 $4')
}
