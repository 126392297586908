import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  message,
  Spin
} from 'antd'

import UserEdit from './UserEdit'
import UserProfile from './UserProfile'
import { useGetUserById } from '../../../api/react-query/users'
import { NEW } from '../../../Constants'
import { useAvailableSubscriptionsQuery, useUserSubscriptionsQuery } from '../../../api/react-query/sa'
// import { AppConfig } from '../../../AppConfig'

function User ({ groups }) {
  const [isEdit, setIsEdit] = useState(false)
  const [user, setUser] = useState({})
  const [height, setHeight] = useState(undefined)
  const { id } = useParams()
  const containerRef = useRef(null)

  const isNewUser = useMemo(() => id === NEW, [id])
  const {
    data: userSubscriptionsData,
    isLoading: userSubscriptionsLoading,
    isError: userSubscriptionsError
  } = useUserSubscriptionsQuery(id, {
    enabled: !!id && !isNewUser
  })

  const {
    data: subscriptionsData,
    isLoading: subscriptionsLoading,
    isError: subscriptionsError
  } = useAvailableSubscriptionsQuery(isNewUser ? null : id)

  const subscriptions = useMemo(() => subscriptionsData?.result || [], [subscriptionsData])
  const userSubscriptions = useMemo(() => userSubscriptionsData?.result || [], [userSubscriptionsData])

  useEffect(() => {
    if (!user || isNewUser) {
      setIsEdit(true)
    }
  }, [isNewUser, user])

  const {
    data: profile,
    isError,
    isSuccess,
    isLoading
  } = useGetUserById(id, {
    enabled: !!id && !isNewUser
  })

  useEffect(() => {
    if (isSuccess) {
      setUser(profile.user)
    }
  }, [isSuccess, profile])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка получения профиля пользователя')
    }
  }, [isError])

  useEffect(() => {
    if (userSubscriptionsError || subscriptionsError) {
      message.error('Ошибка получения подписок пользователя')
    }
  }, [userSubscriptionsError, subscriptionsError])

  useLayoutEffect(() => {
    setHeight(containerRef?.current?.offsetHeight)
  }, [containerRef])

  return (
    <div ref={containerRef} className='tree-container'>
      <Spin spinning={isLoading || userSubscriptionsLoading || subscriptionsLoading} size='large'>
        {(isEdit || isNewUser) ? (
          <UserEdit
            containerHeight={height}
            user={user}
            groups={groups}
            subscriptions={userSubscriptions}
            availableSubscriptions={subscriptions}
            onClose={() => setIsEdit(false)}
          />
        ) : (
          <UserProfile
            containerHeight={height}
            user={user}
            groups={groups}
            subscriptions={userSubscriptions}
            onEdit={() => setIsEdit(true)}
          />)}
      </Spin>
    </div>
  )
}

export default withRouter(observer(User))
