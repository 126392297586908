import { useCallback } from 'react'

import { errors } from '../translates'
import useCommonDict from './useCommonDict'
import { useOrgStoresQuery } from '../api/react-query/org'
import { sortByName } from '../helper'

export default function useStores (data, options) {
  const successCallback = useCallback(data => [...sortByName(data?.result || [])], [])
  return useCommonDict({
    errorMessage: errors.stores,
    queryMethod: useOrgStoresQuery,
    queryBody: data,
    queryOptions: options,
    successCallback
  })
}
