import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import { profileScreen } from '../../../../translates'
import { validateTitleRus } from '../../../../helper'
import SberIcon from '../../SberIcon'
import TinkoffIcon from '../../TinkoffIcon'

const DistrictFormItem = ({
  label = profileScreen.input.registrationAddressDistrict.label,
  rules = undefined,
  requiredRule = false,
  messageRule = profileScreen.input.registrationAddressDistrict.message,
  size = 'large',
  placeholder = profileScreen.input.registrationAddressDistrict.placeholder,
  showSberIcon = false,
  showTinkoffIcon = false
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon
      ? (
        <>
          {label}
          {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
          {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
        </>
      )
      : label
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={['personalData', 'registrationAddress', profileScreen.input.registrationAddressDistrict.name]}
      label={labelParam}
      rules={rules ?? [{ required: requiredRule, message: messageRule, validator: validateTitleRus }]}
    >
      <Input
        size={size}
        placeholder={placeholder}
      />
    </Form.Item>
  )
}
export default DistrictFormItem
