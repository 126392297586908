import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useMemo, useState, useContext, useReducer } from 'react'
import { CopyOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import {
  NameFormItem,
  CitizenshipFormItem,
  BirthDateFormItem,
  EmailFormItem,
  CitySelectorFormItem,
  StoreChainFormItem,
  StoreFormItem,
  PassportBlock,
  PlaceOfBirthFormItem,
  RegistrationAddressBlock
} from '../../../Common'
import {
  createApiDataFromForm,
  validateDate,
  validatePhone,
  showExcept,
  simpleReducer,
  isShownSberIcon, isShownTinkoffIcon, formatDrivingNumber, formatSnils
} from '../../../../helper'
import { profileScreen } from '../../../../translates'
import { useMutateEditPhone } from '../../../../api/react-query/adminPartners'
import { ConfigContext } from '../../../../context/configContext'
import { AppConfig } from '../../../../AppConfig'
import PhotoViewer from '../PhotoViewer/PhotoViewer'
import CheckSberIcon from '../../../Common/CheckSberIcon'
import VehicleNumberFormItem, {
  formatForFrontVehicleNumber
} from '../../../Common/FormItems/VehicleNumberFormItem'
import CheckTinkoffIcon from '../../../Common/CheckTinkoffIcon'
import SnilsDetails from '../../../Partner/Profile/SnilsDetails'
import DrivingDetails from '../../../Partner/Profile/DrivingDetails'

const layout = {
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  },
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}
const { Text } = Typography
const { confirm } = Modal
const userRoles = AppConfig.groups
const partnerRoles = AppConfig.partnerRoles
const initialState = {
  isVisiblePhoto: false
}

const EditPersonalData = ({
  visible = false,
  onCancel = () => {},
  loading = false,
  loadingPartnerData = false,
  loadingDocumentApproval = false,
  partnerId,
  onChange,
  profile,
  backendFieldsErrors = {},
  onCommitDocument = () => {}
}) => {
  const { personalData, allowStoreChange, allowPhoneChange } = profile
  const [form] = Form.useForm()
  const [isVisibleTooltipName, setIsVisibleTooltipName] = useState(false)
  const [isVisibleTooltipMobilePhone, setIsVisibleTooltipMobilePhone] = useState(false)
  const [isVisibleTooltipStore, setIsVisibleTooltipStore] = useState(false)
  const [isEditingPhone, setIsEditingPhone] = useState(false)
  const [isEditingPhoneButtonEnabled, setIsEditingPhoneButtonEnabled] = useState(false)
  const [city, setCity] = useState(personalData?.city)
  const [chain, setChain] = useState(personalData?.storeChain)
  const [hasNfc, setHasNfc] = useState(personalData?.hasNfc)
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { user, isCoordinator, isRecruiter } = useContext(ConfigContext)

  const {
    mutate: editPhone,
    isLoading: isLoadingEditPhone,
    data: dataEditPhone,
    isSuccess: isSuccessEditPhone,
    isError: isErrorEditPhone
  } = useMutateEditPhone()

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  useEffect(() => {
    if (!dataEditPhone?.isSuccess && dataEditPhone?.errorCode === AppConfig.phoneEditErrorCodes.ForceChangeRequired) {
      handleSavePhone(true)
    }
    if (isErrorEditPhone || dataEditPhone?.errorMessage) {
      if (dataEditPhone?.errorMessage) {
        form.setFields([{
          name: 'mobilePhone',
          errors: [dataEditPhone.errorMessage]
        }])
      }
      message.error(dataEditPhone?.errorMessage || 'Не удалось изменить номер телефона.')
    } else if (isSuccessEditPhone && dataEditPhone?.isSuccess) {
      setIsEditingPhone(false)
      message.success('Номер телефона изменен.')
    }
  }, [isErrorEditPhone, dataEditPhone, isSuccessEditPhone])

  useEffect(() => {
    if (personalData) {
      const vehicleNumber = formatForFrontVehicleNumber(personalData[profileScreen.input.vehicleNumber.name])
      const drivingNumber = formatDrivingNumber(personalData?.drivingLicence?.[profileScreen.input.drivingLicenceSeriesNumber.name])
      const snils = formatSnils(personalData?.[profileScreen.input.snils.name])
      form.setFieldsValue({
        personalData: {
          ...personalData,
          [profileScreen.input.vehicleNumber.name]: vehicleNumber,
          [profileScreen.input.snils.name]: snils,
          drivingLicence: {
            ...personalData?.drivingLicence,
            [profileScreen.input.drivingLicenceSeriesNumber.name]: drivingNumber
          }
        }
      })
    }
  }, [personalData])

  const handleOnSave = () => {
    form.validateFields().then(fields => {
      if (!form.isFieldsTouched()) return onCancel()
      const data = createApiDataFromForm(fields)
      if (!data.personalData?.[profileScreen.input.snils.name]) {
        delete data.personalData?.[profileScreen.input.snils.name]
      }
      if (!data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceSeriesNumber.name]) {
        delete data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceSeriesNumber.name]
      }
      if (!data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssuerName.name]) {
        delete data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssuerName.name]
      }
      if (!data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssueDate.name]) {
        delete data.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssueDate.name]
      }
      if (!Object.keys(data.personalData?.drivingLicence).length) {
        delete data.personalData?.drivingLicence
      }
      onChange({ data: data.personalData, id: partnerId })
    }).catch(() => {})
  }
  const okButtonProps = useMemo(() => ({ className: 'green', loading }), [loading])
  const handleCopyName = () => {
    const surname = form.getFieldValue(['personalData', profileScreen.input.surname.name])
    const firstName = form.getFieldValue(['personalData', profileScreen.input.firstName.name])
    const middleName = form.getFieldValue(['personalData', profileScreen.input.middleName.name])
    const text = `${surname} ${firstName} ${middleName}`
    navigator.clipboard.writeText(text.trim()).then(function () {
      setIsVisibleTooltipName(true)
      setTimeout(() => setIsVisibleTooltipName(false), 1500)
    })
  }
  const handleCopyMobilePhone = () => {
    let mobilePhone
    if (isEditingPhone) {
      mobilePhone = form.getFieldValue(['personalData', profileScreen.input.mobilePhone.name]) || ''
    } else {
      mobilePhone = personalData?.mobilePhone || ''
    }
    navigator.clipboard.writeText(mobilePhone.trim()).then(function () {
      setIsVisibleTooltipMobilePhone(true)
      setTimeout(() => setIsVisibleTooltipMobilePhone(false), 1500)
    })
  }
  const handleCopyStore = () => {
    const text = form.getFieldValue(['personalData', profileScreen.input.store.name]) || ''
    navigator.clipboard.writeText(text.trim()).then(function () {
      setIsVisibleTooltipStore(true)
      setTimeout(() => setIsVisibleTooltipStore(false), 1500)
    })
  }
  const handleChangeCity = (city) => {
    setCity(city)
    form.setFieldsValue({ personalData: { ...form.getFieldsValue(), store: '', storeChain: '' } })
  }
  const handleChangeChain = (chain) => {
    setChain(chain)
    form.setFieldsValue({ personalData: { ...form.getFieldsValue(), store: '' } })
  }
  const handleChangeStore = (store) => {
    form.setFieldsValue({ personalData: { ...form.getFieldsValue(), storeId: store.id } })
  }
  const handleChangeFields = (fields) => {
    if (fields?.[0]?.name?.[1] === 'mobilePhone') {
      setIsEditingPhoneButtonEnabled(fields?.[0]?.value !== personalData?.mobilePhone)
    }
  }
  const handleChangeNFC = ({ target }) => {
    form.setFieldsValue({ personalData: { ...form.getFieldsValue(), hasNfc: target.checked } })
    setHasNfc(target.checked)
  }

  const handleClickSavePhone = () => handleSavePhone()

  const handleClickChangePhone = () => {
    form.setFieldsValue({ personalData: { ...form.getFieldsValue(), mobilePhone: personalData?.mobilePhone } })
    setIsEditingPhone(true)
  }

  const handleSavePhone = (forceChange = false) => {
    confirm({
      title: forceChange
        ? 'Данный номер уже зарегистрирован в новой анкете'
        : 'Изменение телефона',
      content: forceChange
        ? 'Потвердите замену телефона и после потдверждения дублирующая анкета будет отправлена в архив'
        : 'Вы точно хотите изменить номер телефона партнера?',
      onOk: () => {
        form.validateFields(['personalData', 'mobilePhone']).then(({ personalData: { mobilePhone } }) => {
          editPhone({
            id: partnerId,
            mobilePhone,
            ...(forceChange && { forceChange })
          })
        })
      },
      cancelText: 'Отмена'
    })
  }

  const handleTogglePhoto = () => {
    setState({ isVisiblePhoto: !state.isVisiblePhoto })
  }

  const documentList = useMemo(() => {
    return AppConfig.documentNameList.filter(d => ![
      profileScreen.input.lmkMainPhoto.name,
      profileScreen.input.lmkAllowancePhoto.name,
      profileScreen.input.lmkAttestationPhoto.name
    ].includes(d) || (
      [
        profileScreen.input.lmkMainPhoto.name,
        profileScreen.input.lmkAllowancePhoto.name,
        profileScreen.input.lmkAttestationPhoto.name
      ].includes(d) && !isCoordinator && !isRecruiter
    ))
  }, [AppConfig.documentNameList, isCoordinator, isRecruiter])

  const DISABLE_FIELDS = true

  return (
    <Modal
      width={state.isVisiblePhoto ? '90%' : 700}
      visible={visible}
      title='Редактирование персональных данных партнера'
      onCancel={onCancel}
      okText='Сохранить'
      cancelText='Отменить'
      okButtonProps={okButtonProps}
      onOk={handleOnSave}
      destroyOnClose
    >
      <Row>
        {state.isVisiblePhoto && <Col span={12} />}
        <Col span={state.isVisiblePhoto ? 12 : 24}>
          {state.isVisiblePhoto
            ? <CaretRightOutlined onClick={handleTogglePhoto} style={{ fontSize: 20 }} />
            : <CaretLeftOutlined onClick={handleTogglePhoto} style={{ fontSize: 20 }} />}
        </Col>
      </Row>
      <Row>
        {state.isVisiblePhoto && (
          <Col span={12}>
            <PhotoViewer
              documents={documentList}
              profile={profile}
              onCommitDocument={onCommitDocument}
              loading={loadingDocumentApproval}
            />
          </Col>
        )}
        <Col span={state.isVisiblePhoto ? 12 : 24}>
          <Form
            {...layout}
            name='editPartnerPersonalData'
            form={form}
            scrollToFirstError
            onFieldsChange={handleChangeFields}
          >
            <div className='with-copy-icon with-border'>
              <Tooltip visible={isVisibleTooltipName} placement='topLeft' title='ФИО скопировано в буфер обмена' trigger='click'>
                <CopyOutlined onClick={handleCopyName} />
              </Tooltip>
              <NameFormItem
                name={['personalData', 'surname']}
                placeholder='Иванов'
                messageRule='Пожалуйста, укажите фамилию партнера'
                label='Фамилия'
                size='middle'
                showSberIcon={isShownSberIcon('surname', profile)}
                showTinkoffIcon={isShownTinkoffIcon('surname', profile)}
              />
            </div>
            <NameFormItem
              name={['personalData', 'firstName']}
              placeholder='Иван'
              messageRule='Пожалуйста, укажите имя партнера'
              label='Имя'
              size='middle'
              showSberIcon={isShownSberIcon('firstName', profile)}
              showTinkoffIcon={isShownTinkoffIcon('firstName', profile)}
            />
            <NameFormItem
              name={['personalData', 'middleName']}
              placeholder='Иванович'
              messageRule='Пожалуйста, укажите отчество партнера'
              label='Отчество'
              requiredRule={false}
              size='middle'
              showSberIcon={isShownSberIcon('middleName', profile)}
              showTinkoffIcon={isShownTinkoffIcon('middleName', profile)}
            />
            {
              !DISABLE_FIELDS &&
              showExcept(user.groupName, [userRoles.contentcoordinators, userRoles.researchercoordinators]) &&
              ![partnerRoles.szContent, partnerRoles.researcher].includes(personalData?.roleCode) &&
              (
                <Form.Item
                  label={
                    <>
                      Наличие NFC
                      <CheckSberIcon profileAndSberIdProperty='hasNfc' profile={profile} style={{ marginLeft: '0.5rem' }} />
                      <CheckTinkoffIcon profileAndTinkoffIdProperty='hasNfc' profile={profile} style={{ marginLeft: '0.5rem' }} />
                    </>
                  }
                  name={['personalData', 'hasNfc']}
                >
                  <Checkbox onChange={handleChangeNFC} checked={hasNfc} />
                </Form.Item>
              )
            }
            {
              personalData?.emailRequired && (
                <EmailFormItem
                  showSberIcon={isShownSberIcon('email', profile)}
                  showTinkoffIcon={isShownTinkoffIcon('email', profile)}
                  size='middle'
                />
              )
            }
            {
              personalData?.roleCode === AppConfig.partnerRoles.automotiveCourier && (
                <VehicleNumberFormItem
                  size='middle'
                  form={form}
                  requiredRule={false}
                  hideForeignFormat
                  initValues={{
                    [profileScreen.input.isForeignVehicleNumber.name]: true
                  }}
                />
              )
            }
            <CitizenshipFormItem
              size='middle'
              showSberIcon={isShownSberIcon('citizenship', profile)}
              showTinkoffIcon={isShownTinkoffIcon('citizenship', profile)}
            />
            <BirthDateFormItem
              size='middle'
              validator={validateDate}
              showSberIcon={isShownSberIcon('birthDate', profile)}
              showTinkoffIcon={isShownTinkoffIcon('birthDate', profile)}
            />
            <PlaceOfBirthFormItem
              size='middle'
              requiredRule={hasNfc}
              showSberIcon={isShownSberIcon('placeOfBirth', profile)}
              showTinkoffIcon={isShownTinkoffIcon('placeOfBirth', profile)}
            />
            <PassportBlock size='middle' headerOrientation='center' hideBottomDivider profile={profile} />
            <RegistrationAddressBlock
              profile={profile}
              size='middle'
              headerOrientation='center'
              hideBottomDivider
              required={{
                zipCode: hasNfc,
                country: hasNfc,
                region: hasNfc,
                street: hasNfc,
                house: hasNfc
              }}
            />

            <SnilsDetails
              headerOrientation='center'
              size='middle'
              hideBottomDivider
              showInputs
              notRequired={!profile?.personalData?.[profileScreen.input.snils.name]}
            />

            <DrivingDetails
              headerOrientation='center'
              size='middle'
              hideBottomDivider
              showInputs
              notRequired={
                !profile?.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceSeriesNumber.name] &&
                !profile?.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssuerName.name] &&
                !profile?.personalData?.drivingLicence?.[profileScreen.input.drivingLicenceIssueDate.name]
              }
            />

            {
              !DISABLE_FIELDS &&
              showExcept(user.groupName, [userRoles.contentcoordinators, userRoles.researchercoordinators]) &&
              ![partnerRoles.szContent, partnerRoles.researcher].includes(personalData?.roleCode) &&
              (
                allowStoreChange
                  ? (
                    <CitySelectorFormItem
                      size='middle'
                      label='Город'
                      rules={[{ required: true, message: profileScreen.input.city.message }]}
                      onChange={handleChangeCity}
                    />
                  )
                  : (
                    <Form.Item label='Город' className='middle-margin-bottom'>
                      <Text>{personalData?.city}</Text>
                    </Form.Item>
                  )
              )
            }

            {
              !DISABLE_FIELDS &&
              showExcept(user.groupName, [userRoles.contentcoordinators, userRoles.researchercoordinators]) &&
              ![partnerRoles.szContent, partnerRoles.researcher].includes(personalData?.roleCode) &&
              (
                allowStoreChange
                  ? (
                    <StoreChainFormItem
                      size='middle'
                      label='Сеть'
                      city={city}
                      rules={[{ required: true, message: profileScreen.input.storeChain.message }]}
                      disabled={!city}
                      onChange={handleChangeChain}
                    />
                  )
                  : (
                    <Form.Item label='Сеть' className='middle-margin-bottom'>
                      <Text>{personalData?.storeChain}</Text>
                    </Form.Item>
                  )
              )
            }

            {
              !DISABLE_FIELDS &&
              showExcept(user.groupName, [userRoles.contentcoordinators, userRoles.researchercoordinators]) &&
              ![partnerRoles.szContent, partnerRoles.researcher].includes(personalData?.roleCode) &&
              (
                <div className='with-copy-icon'>
                  <Tooltip visible={isVisibleTooltipStore} placement='topLeft' title='Магазин скопирован в буфер обмена' trigger='click'>
                    <CopyOutlined onClick={handleCopyStore} />
                  </Tooltip>
                  {
                    allowStoreChange
                      ? (
                        <>
                          <StoreFormItem
                            size='middle'
                            city={city}
                            chain={chain}
                            rules={[{ required: true, message: profileScreen.input.store.message }]}
                            disabled={!chain}
                            onChange={handleChangeStore}
                          />
                          <Form.Item name={['personalData', 'storeId']} noStyle>
                            <Input hidden />
                          </Form.Item>
                        </>
                      ) : (
                        <Form.Item label='Магазин' className='middle-margin-bottom'>
                          <Text>{personalData?.store}</Text>
                        </Form.Item>
                      )
                  }
                </div>
              )
            }

            <div className='with-copy-icon'>
              <Tooltip visible={isVisibleTooltipMobilePhone} placement='topLeft' title='Номер телефона скопирован в буфер обмена' trigger='click'>
                <CopyOutlined onClick={handleCopyMobilePhone} />
              </Tooltip>
              <Form.Item label={<>Номер телефона</>}>
                <Row gutter={15}>
                  {
                    isEditingPhone
                      ? (
                        <>
                          <Col span={10}>
                            <Form.Item
                              name={['personalData', 'mobilePhone']}
                              rules={[
                                { required: true, message: profileScreen.input.mobilePhone.message },
                                { validator: validatePhone, message: profileScreen.input.mobilePhone.message }
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={14}>
                            <Button
                              loading={isLoadingEditPhone}
                              disabled={!isEditingPhoneButtonEnabled}
                              type='primary'
                              onClick={handleClickSavePhone}
                            >Сохранить
                            </Button>
                            <Button className='ml-3' onClick={() => setIsEditingPhone(false)}>Отменить</Button>
                          </Col>
                        </>)
                      : <Col><Text className='phone'><Spin spinning={loadingPartnerData}> {personalData?.mobilePhone}</Spin></Text></Col>
                  }
                  {
                    allowPhoneChange && !isEditingPhone &&
                      <Col><Button type='primary' onClick={handleClickChangePhone}>Изменить</Button></Col>
                  }
                </Row>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}
export default EditPersonalData
