import React from 'react'
import { Col, Row } from 'antd'

function FilterTitle ({ title, children }) {
  return (
    <Row gutter={[12, 6]} justify='start'>
      <Col xl={4} md={6} xs={12}>
        {title}
      </Col>
      <Col className='filter-input'>
        {children}
      </Col>
    </Row>
  )
}

export default FilterTitle
